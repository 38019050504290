<template>
  <b-sidebar
    id="plan-sidebar"
    :visible="isPlanSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-plan-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Choose a package
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Package Plan -->
          <validation-provider
            #default="validationContext"
            name="Package Plan"
            rules="required"
          >
            <b-form-group
              label="Select a Package"
              label-for="package-plan"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="form.package_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="packageOptions"
                :reduce="val => val.id"
                label="name"
                :clearable="true"
                input-id="package-plan"
                @input="setMonth"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- month -->
          <validation-provider
            #default="validationContext"
            name="month"
            rules="required"
          >
            <b-form-group
              label="For"
              label-for="month"
            >
              <b-input-group >
                <b-form-input
                  id="month"
                  v-model.number="form.month"
                  :state="getValidationState(validationContext)"
                  trim
                  type="number"
                  :min="min_month"
                  step="1"
                  @input="setPrice"
                />

                <b-input-group-append  is-text>
                    month(s)
                </b-input-group-append>

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>

              </b-input-group >
            </b-form-group>
            <p v-if="total_price">Total Cost: ${{total_price.toFixed(2)}}</p>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BInputGroupAppend, BInputGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onMounted } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'

// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.bd'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BInputGroupAppend,
    BInputGroup,
    vSelect,
    Cleave,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isPlanSidebarActive',
    event: 'update:is-plan-sidebar-active',
  },
  props: {
    isPlanSidebarActive: {
      type: Boolean,
      required: true,
    },
    orgData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      total_price: '',
      clevePhone: {
        phone: true,
        phoneRegionCode: 'US',
      },
    }
  },
  methods: {
    setPrice() {
      this.total_price = parseFloat(this.plan.price * (this.form.month / this.min_month) );
    },
    setMonth() {
        var package_id = this.form.package_id;
        this.min_month = null;
        if(package_id){
          const dataIndex = this.packageOptions.findIndex((u) => u.id === package_id)
          this.plan = this.packageOptions[dataIndex]

          if(this.plan){
            if(this.plan.duration == "Year"){
              this.min_month = 12;
            }
            else if(this.plan.duration == "Month"){
              this.min_month = 1;
            }
            else{
              this.min_month = 1;
            }
          }
        }
        this.form.month = this.min_month;
        this.setPrice();
    },

    onSubmit() {
      store.dispatch('app-org/updateOrgPackage', this.form)
        .then((response) => {
          if(response.data.status == 1 || response.data.success){
            this.$emit('refetch-data')
            this.$emit('update:is-plan-sidebar-active', false)
            this.$swal({
                  icon: 'success',
                  title: response.data.message ? response.data.message : 'successfully added!',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                  timeout: 2000
                })
          }
          else if(response.data.status == 0){
            var errors = response.data.errors;
              this.$swal({
                    icon: 'error',
                    title: errors.join(', ' ),
                    customClass: {
                      confirmButton: 'btn btn-danger',
                    },
                  })
            }
      })
    }
  },
  setup(props) {
    var min_month;
    var name;
    var plan;
    var blankData = {
      id: props.orgData.id,
      package_id: '',
      month: ''
    }

    if(props.orgData.package && props.orgData.package.package){
      blankData.package_id = props.orgData.package.package_id
      name = props.orgData.package.package.name
      if(props.orgData.package.package.duration == "Year"){
        min_month = 12;
      }
      else if(props.orgData.package.package.duration == "Month"){
        min_month = 1;
      }
      else{
        min_month = 1;
      }

      blankData.month = min_month;
    }

    var form = blankData
    const resetData = () => {
      form = blankData //JSON.parse(JSON.stringify(blankData))
    }

    const getPackageData = async () => {
      store.dispatch('app-org/fetchPackageOption')
        .then((response) => {
          if(response.data.status == 1)
            packageOptions.value = response.data.data
      }).catch(error=> {})
    }

    onMounted(getPackageData)
    const packageOptions = ref([])

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)

    return {
      form,
      min_month,
      name,
      plan,
      packageOptions,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.input-group-append .input-group-text {
    border-right: 1px solid #d8d6de !important;
}
</style>
