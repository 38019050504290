<template>
  <b-card
    no-body
    class="border-primary"
  >
    <renew-sidebar
      :is-add-new-sidebar-active.sync="isAddNewSidebarActive"
      :org-data="orgData"
      @refetch-data="refetchData"
    />
    <plan-sidebar
      :is-plan-sidebar-active.sync="isPlanSidebarActive"
      :org-data="orgData"
      @refetch-data="refetchData"
    />

    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h5 class="mb-0">
        Current Plan
      </h5>
      <b-badge variant="light-primary" v-if="orgData.package">
        {{ orgData.package.title}}
      </b-badge>
      <small v-if="orgData.package" class="text-muted w-100">{{ formatDate (orgData.package.expired_date)}}</small>
    </b-card-header>

    <b-card-body>
      <ul class="list-unstyled my-1">
        <li>
          <span class="align-middle">{{ orgData.total_users}} Users</span>
        </li>
        <li class="my-25">
          <span class="align-middle">10 GB storage</span>
        </li>
        <li>
          <span class="align-middle">Basic Support</span>
        </li>
      </ul>
      <b-button  v-if="orgData.package"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="danger"
        block
        @click="isAddNewSidebarActive = true"
      >
        Renew Plan
      </b-button>
      <b-button  v-else
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        @click="isPlanSidebarActive = true"
      >
        Choose Plan
      </b-button>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BBadge, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { formatDate } from '@core/utils/filter'
import RenewSidebar from './RenewSidebar.vue'
import PlanSidebar from './PlanSidebar.vue'
import { ref } from '@vue/composition-api'
import store from '@/store'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
    RenewSidebar,
    PlanSidebar,
  },
  props: {
    orgData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formatDate
    }
  },
  methods: {
    refetchData() {
      this.$emit('refetch-data');
    }
  },
  setup() {
    const isAddNewSidebarActive = ref(false)
    const isPlanSidebarActive = ref(false)
    return {
      // Sidebar
      isAddNewSidebarActive,
      isPlanSidebarActive,
    }
  },
}
</script>

<style>

</style>
